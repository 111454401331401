import { isTeamMember } from "~/utils/roles";

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp();
  const user = useSupabaseUser();

  // If no user yet, wait for auth
  if (!user.value) {
    return;
  }

  const { teamMember, userRoles, refetch, isSlackIdSet, isExternalCsm } = useUserMe();
  const { linkSlackOnce } = useLinkSlack();
  const { isDevelopment } = useFeatureFlag();
  const { addToastWarning } = useToast();

  try {
    // If we are hydrating or don't have team member data, fetch it
    if (nuxtApp.isHydrating || !teamMember.value) {
      await refetch();
    }

    // If data corrupted (user has no roles) or user has been offboarded
    if (!isTeamMember(userRoles.value) || teamMember.value?.offboarded_at) {
      return navigateTo("/403");
    }

    // If user needs onboarding
    if (!teamMember.value?.is_onboarded) {
      return navigateTo("/onboarding");
    }

    // If user is a team member but has no slack id set, we fetch it
    // (only if not hydrating, cause we use notify and it's available after first load)
    // (in development if you want to have the slack id set, you can use the /profile page)
    if (!isDevelopment && !nuxtApp.isHydrating && !isSlackIdSet.value) {
      linkSlackOnce();
    }

    // TODO: remove this, as external CSM has CSM Role OR scope to her role and we keep this code
    const authorizedRoutes = [
      "/dashboard",
      "/ppe-requests",
      "/qualification-training-requests",
      "/professional-card-requests",
      "/medical-checkup-requests",
    ];

    if (isExternalCsm.value && !authorizedRoutes.some((route) => to.path.startsWith(route))) {
      addToastWarning({ description: "Vous n'êtes pas autorisé à accéder à la page." });
      return navigateTo("/");
    }
  } catch (error) {
    console.error("Error in team-member middleware:", error);
    return navigateTo("/login");
  }
});
